import React, {useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import moment from 'moment';

import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

// Charts
import CompetitionTable from '../components/widgets/CompetitionTable';
import CompetitionTable2 from '../components/widgets/CompetitionTable2';
import WallOfFame from '../components/widgets/WallOfFame';

export const Competition = (props) => {

	//STATE
	const [filterData, setFilterData] = useState({
		active_start_date: new Date('2024-11-21'),
		active_end_date: new Date('2024-12-24'),	
	});

	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Dashboard"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="page-content-header">
								
								<div className="page-content-header-titel-block">
									<h1 className="page-title">Kogebogs-konkurrence 2024</h1>

									<div className="page-description">
										<p>
											Hvem kan få flest kogebøger sendt ud som årets mandelgaver og julegaver!? <span role="img" aria-label="trophy">🏆</span> <span role="img" aria-label="celebration">🎉</span>
											<br/>
											{moment(filterData.active_start_date).format('DD/MM/YYYY')} — {moment(filterData.active_end_date).format('DD/MM/YYYY')}
										</p>
									</div>
								</div>

							</div>
						
							<div className="page-charts">

								<CompetitionTable
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
								/>

								{/*
								<CompetitionTable2
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
								/>
								*/}

								<WallOfFame/>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Competition, {
  onRedirecting: () => <Loading />,
});
